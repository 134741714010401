import { React, useState, useEffect } from 'react'
import { baseURL, getToken } from '../../utils/utils';
import Header from '../../components/Header/Header';
import BattleCard from '../../components/Battle/BattleCard';
import BattleDetail from '../../components/Battle/BattleDetail';
import Dice
  from '../../components/Battle/Dice';
import "./BattlePage.css"

const BattlePage = () => {
  const [battles, setBattles] = useState([]);
  const [listAll, setListAll] = useState(true);
  const [page, setPage] = useState("dice");
  const [battleId, setBattleId] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      window.location.href = "/";
    }
    fetch(baseURL() + 'battle/find_all', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      // You can include any additional headers or body data as needed
      body: JSON.stringify({ "token": token })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Update state with fetched data
        setBattles(data.battles);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleBattleCardClick = (id) => {
    setListAll(false); // Set listAll state to false to hide all battle cards
    setPage("battle");
    setBattleId(id); // Set detailId state to the id of the clicked BattleCard
  };

  const handleBattleDetailClick = () => {
    setListAll(true); // Set listAll state to false to hide all battle cards
    setBattleId(null); // Set detailId state to the id of the clicked BattleCard
    window.location.href = "/battle"
  };

  const handleNewBattle = () => {
    let token = getToken();
    // Send request to delete battle
    fetch(baseURL() + 'battle/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "token": token })
    })
      .then(response => {
        // Check if response is okay
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse response as JSON
      })
      .then(data => {
        // Handle fetched data
        if (data.status_code !== 200) {
          alert(data.message);
        }
        window.location.href = "/battle";
      })
  }

  let battleCards = battles.map((item, index) => (
    <BattleCard
      key={index} // Make sure to add a unique key prop
      battle={item}
      onClick={() => handleBattleCardClick(item.id)} // Pass the click handler to BattleCard
    />
  ));
  
  return (
    <div>
      <Header />
      <div className={`container ${listAll ? 'battle_container' : ''}`}>
        {listAll && battleCards}
        {!listAll && page === "battle" &&
          <BattleDetail
            battle_id={battleId} onClick={handleBattleDetailClick} changePage={()=>{setPage('dice')}}/>}
        {!listAll && page === "dice" &&
          <Dice onClick={() => {
              if(listAll){setListAll(true);setBattleId(null)} 
              else {setPage("battle")}
            }}/>}
      </div>
      <div className='function_bar'>
        {listAll && <button onClick={handleNewBattle}> + </button>}
      </div>

    </div>

  )
}

export default BattlePage