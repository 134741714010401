import React, { useState } from 'react';
import { fetchData } from '../../utils/utils.js';

const ExpectedDamage = () => {
  const [selectedWeapon, setSelectedWeapon] = useState({
    num: "1",
    A: "2",
    HR: "3",
    S: "4",
    AP: "1",
    D: "2"
  });

  const [selectedUnit, setSelectedUnit] = useState({
    num: "1",
    T: "4",
    SV: "3",
    ISV: "",
    W: "2"
  });

  const [weaponSkills, setWeaponSkills] = useState({
    ReRoll: false,
    DualLink: false,
    LethalHit: false,
    Devastating: false,
    SustainedHit: false,
    Lance: false
  });

  const [weaponSkillsValue, setWeaponSkillsValue] = useState({
    SustainedHit: 1,
  });

  const [change, setChange] = useState(0);

  // Function to handle weapon input cha  nges
  const handleWeaponChange = (field, value) => {
    setSelectedWeapon(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  // Function to handle unit input changes
  const handleUnitChange = (field, value) => {
    setSelectedUnit(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const [expectedDamage, setExpectedDamage] = useState(null);

  // Function to calculate expected damage based on weapon and unit
  const calculateExpectedDamage = async () => {
    if (selectedWeapon && selectedUnit) {
      try {
        let skills = [];
        for (let index = 0; index < skillNames.length; index++) {
          const skillname = skillNames[index];
          if(weaponSkills[skillname]){
            if(skillname in weaponSkillsValue){
              skills.push(skillname+" "+String(weaponSkillsValue[skillname]));
            } else {
              skills.push(skillname);
            }
          }
        }
        const response = await fetchData("calculate/expected_damage", {
          weapon: selectedWeapon,
          unit: selectedUnit,
          skills: skills
        });

        // Set the expected damage based on the API response
        if (response) {
          const damage = response.damage;
          // Example calculation: weapon base damage minus unit toughness (simplified)
          setExpectedDamage(damage > 0 ? damage : 0); // Ensure no negative damage
          setChange(response.change);
        } else {
          console.error("No result in response");
          setExpectedDamage(0);
        }
      } catch (error) {
        console.error("Error calculating expected damage:", error);
        setExpectedDamage(0); // In case of an error, set damage to 0
      }
    } else {
      console.warn("Weapon or Unit not selected");
      setExpectedDamage(0);
    }
  };



  const toggleSkill = (skill) => {
    setWeaponSkills(prevState => ({
      ...prevState,
      [skill]: typeof prevState[skill] === 'boolean'
        ? !prevState[skill]
        : prevState[skill] // Do nothing if it's a number
    }));
  };

  const handleSkillChange = (skill, value) => {
    setWeaponSkillsValue(prevState => ({
      ...prevState,
      [skill]: value
    }));
  };
  const skillNames = Object.keys(weaponSkills);

  return (
    <div className='expected-damage'>
      <h2>Expected Damage Calculator</h2>

      <div className='selection_container'>
        <table>
          <thead>
            <tr>
              <td>#</td>
              <td>A</td>
              <td>BS</td>
              <td>S</td>
              <td>AP</td>
              <td>D</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><input type="text" value={selectedWeapon?.num} onChange={(e) => handleWeaponChange('num', e.target.value)} /></td>
              <td><input type="text" value={selectedWeapon?.A} onChange={(e) => handleWeaponChange('A', e.target.value)} /></td>
              <td><input type="text" value={selectedWeapon?.HR} onChange={(e) => handleWeaponChange('HR', e.target.value)} />+</td>
              <td><input type="text" value={selectedWeapon?.S} onChange={(e) => handleWeaponChange('S', e.target.value)} /></td>
              <td>-<input type="text" value={selectedWeapon?.AP} onChange={(e) => handleWeaponChange('AP', e.target.value)} /></td>
              <td><input type="text" value={selectedWeapon?.D} onChange={(e) => handleWeaponChange('D', e.target.value)} /></td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <td>#</td>
              <td>T</td>
              <td>SV</td>
              <td>ISV</td>
              <td>W</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><input type="text" value={selectedUnit?.num} onChange={(e) => handleUnitChange('num', e.target.value)} /></td>
              <td><input type="text" value={selectedUnit?.T} onChange={(e) => handleUnitChange('T', e.target.value)} /></td>
              <td><input type="text" value={selectedUnit?.SV} onChange={(e) => handleUnitChange('SV', e.target.value)} />+</td>
              <td><input type="text" value={selectedUnit?.ISV} onChange={(e) => handleUnitChange('ISV', e.target.value)} />+</td>
              <td><input type="text" value={selectedUnit?.W} onChange={(e) => handleUnitChange('W', e.target.value)} /></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='selection_container'>
        {skillNames.map(skill => (

          <div
            className={`weapon_skill ${weaponSkills[skill] ? 'selected' : ''}`}
            onClick={() =>toggleSkill(skill)}
          >
            <span>
              {skill.replace(/([A-Z])/g, ' $1').trim()}
              {skill in weaponSkillsValue && <input
                type="number"
                value={weaponSkillsValue[skill]}
                onChange={(e) => handleSkillChange(skill, e.target.value)} />}
            </span>
          </div>
        ))}
      </div>


      {/* Display the expected damage */}
      <div className='result'>
        <p>
          Expected Damage: <strong>{expectedDamage}
            {change !== "0" && ` (${change}%)`}
          </strong>
        </p>
        <button onClick={calculateExpectedDamage}>Calculate Expected Damage</button>
      </div>
    </div>
  );
};

export default ExpectedDamage;
