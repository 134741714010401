import {React, useState, useEffect} from 'react'
import "./ProfileForm.css"
import { baseURL } from '../../utils/utils';


function ProfileForm() {
    // State variables for data
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(() => {
        const token = localStorage.getItem("token");
        if(token == null){
            window.location.href = "/";
        }
        fetch(baseURL()+'auth/profile', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            // You can include any additional headers or body data as needed
            body: JSON.stringify({ "token": token })
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            // Update state with fetched data
            setUsername(data.user_name);
            setFirstName(data.first_name);
            setLastName(data.last_name);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
    }, []);
    

    return (
        <div>
            <div>User Name: {username}</div>
            <div>First Name: {firstName}</div>
            <div>Last Name: {lastName}</div>
        </div>
    );
}

export default ProfileForm;