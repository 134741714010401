import { React, useState, useEffect } from 'react'
import { baseURL, getToken } from '../../utils/utils';
import Header from '../../components/Header/Header';
import "./MarketPage.css"
import MarketCard from '../../components/Market/MarketCard';
import MarketForm from '../../components/Market/MarketForm';
import MarketView from '../../components/Market/MarketView';

const MarketPage = () => {
    const [markets, setMarkets] = useState([]);
    const [state, setState] = useState("list");
    const [idx,setIdx] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token == null) {
        window.location.href = "/";
        }
        fetch(baseURL() + 'market/list', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        // You can include any additional headers or body data as needed
        body: JSON.stringify({ "token": token })
        })
        .then(response => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Update state with fetched data
            setMarkets(data.markets);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);
    const handleNewMarket = () => {
        setState("new");
    }

    let marketCards = markets.map((item, index) => (
        <MarketCard
          key={index} // Make sure to add a unique key prop
          market={item}
          onClick={()=>{setIdx(index);setState("view");}}
        />
      ));
  return (
    <div>
      <Header />
      <div className={`container market_card_containers`}>
        {state === "list" && marketCards}
        {state === "new" && <MarketForm back={()=>{setState('list')}}/>}
        {state === "view" && <MarketView back={()=>{setState('list')}} formData={markets[idx]}/>}
      </div>
      <div className='function_bar'>
        {state === "list" && <button onClick={handleNewMarket}> + </button>}
      </div>

    </div>

  )
}

export default MarketPage