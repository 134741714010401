import { React } from 'react'
import Header from '../../components/Header/Header';
import Dice
  from '../../components/Battle/Dice';
import "./BattlePage.css"

const DicePage = () => {
 
  return (
    <div>
      <Header />
      <div className={`container`}>
          <Dice />
      </div>

    </div>

  )
}

export default DicePage