import { React, useEffect, useState } from 'react'
import "./battle.css"
import { baseURL, getToken } from '../../utils/utils';
import { RxCross1 } from "react-icons/rx";
import { FaDice } from "react-icons/fa";

import Form from '../Form/Form';
const BattleDetail = ({ battle_id, onClick, changePage }) => {
  const [battle, setBattle] = useState({});
  const [isCP, setIsCP] = useState(true);
  const [value, setValue] = useState(0);
  const [isYou, setisYou] = useState(true);

  const fetchData = async () => {
    let token = getToken();
    try {
      const response = await fetch(baseURL() + 'battle/detail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "token": token, "battle_id": battle_id })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setBattle(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, [battle_id]);

  const nextTurn = () => {
    let token = getToken();
    fetch(baseURL() + 'battle/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "token": token, "battle_id": battle_id, "cp": 0, "vp": 0, "turn": 1, "username":battle.owner_id})
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(async data => {
      // Handle fetched data
      if (data.status_code !== 200) {
        alert(data.message);
      }
      await fetchData();
    });
  }

  const handleSubmit = () => {
    let token = getToken();
    if(value === ""){return;}
    let change_value = value;
    let cp = 0;
    let vp = 0;
    if(isCP){
      cp = change_value;
    } else{
      vp = change_value;
    }
    let turn = 0;
    let change_player = isYou ? battle.owner_id : battle.player_id;
    fetch(baseURL() + 'battle/update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },

      body: JSON.stringify({ "token": token, "battle_id": battle_id, "cp": cp, "vp": vp, "turn": turn, "username":change_player})
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }).then(async data => {
      // Handle fetched data
      if (data.status_code !== 200) {
        alert(data.message);
      }
      await fetchData();
    })
  }

  const handleDelete = () => {
    // Get token from local storage
    let token = getToken();
    // Send request to delete battle
    fetch(baseURL() + 'battle/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "token": token, "battle_id": battle_id })
    })
      .then(response => {
        // Check if response is okay
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse response as JSON
      })
      .then(data => {
        // Handle fetched data
        if (data.status_code !== 200) {
          alert(data.message);
        }
        onClick(); // Call onClick function
        window.location.href = "/battle";
      })
  };

  const [form, setForm] = useState(false);

  const handleAddPlayer = (formData) => {
    let token = getToken();
    let username = formData.username;
    fetch(baseURL() + 'battle/add_player', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "token": token, "battle_id": battle_id, "username": username })
    })
      .then(response => {
        // Check if response is okay
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse response as JSON
      })
      .then(async data => {
        // Handle fetched data
        if (data.status_code !== 200) {
          alert(data.message);
        } else {
          setForm(false);
        }
        await fetchData();
      })
  }

  const openForm = () => {
    setForm(true);
  }

  const inputs = [
    { type: 'username', name: 'username', label: 'Username', placeholder: "Enter your opponent's username", required: true },
  ];

  return (
    <div className='card battle_detail_container' >
      <div className='button'>
        <RxCross1 onClick={onClick} />
      </div>
      <div className='turn'>
        Round {battle.turn}
      </div>
      <div className='vp'>
        <span className='owner'>{battle.vp?.[0]}</span>
        -
        <span className='player'>{battle.vp?.[1]}</span>
      </div>

      <div className='player_container'>
        <div className='owner'>{battle.owner}</div>
        {battle.player !== "" && <div className='player'>{battle.player}</div>}
        {battle.player === "" && <div className='player invite_player' onClick={openForm}>+</div>}
      </div>
      {form && <Form inputs={inputs} onSubmit={handleAddPlayer} />}
      <div className='section_container'>
        <div className='owner'>{battle.cp?.[0]}</div>
        <div >CP</div>
        <div className='player'>{battle.cp?.[1]}</div>
      </div>

      {battle.owner === battle.user &&
      <>
        <div className='switch_title'>
          <div style={{"float":'left'}}>Owner</div> <div style={{"float":'right'}}>Player</div>
        </div>
        
        <div className='switch_container' onClick={()=>{setisYou(!isYou)}}>
          <div className={'switch ' + (isYou ? '' : 'right')}>
            <div className='dot'></div>
          </div>
        </div>


        <div className='input_container'>
     
          <button onClick={()=>{setIsCP(!isCP)}}> {isCP ? "CP": "VP"} </button>
          <input 
            type="text" 
            value={value} 
            onChange={(event) => {
              const inputValue = event.target.value;
              if (inputValue === "-") {
                setValue(inputValue);
                return;
              } else if(isNaN(inputValue) || inputValue === "" ) {
                setValue("");
                return;
              }
              // Convert the input to an integer
              const intValue = parseInt(inputValue, 10);
              // Only set the value if it's a valid number
              setValue(intValue);
            }} 
            placeholder="Enter a number" 
          />
        </div>
        <div className='submit_container'>
          <button onClick={handleSubmit}> Submit </button>
        </div>

        <div className='submit_container'>
          {
            <button className='dice' onClick={changePage}>
              <FaDice />
            </button>
          }
          <button className = "next_turn" onClick={nextTurn}> Next Turn </button>
        </div>

        <div className='submit_container'>
          <button className='remove_battle' onClick={handleDelete}>DELETE</button>
        </div>
      </>  
      }
      {battle.owner !== battle.user && 
        <div className='submit_container'>
          <button  onClick={()=>{fetchData()}}>Refresh</button>
        </div>
      }
    </div>
  )
}

export default BattleDetail