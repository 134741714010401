import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import "./Profile.css"
function ProfilePage() {
    
    return (
        <div className='profile'>
            <Header />
            <div className='container'>
                <ProfileForm />
            </div>
        </div>
    );
}

export default ProfilePage;
