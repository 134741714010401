import { React, useState } from 'react'
import { RxCross1 } from "react-icons/rx";
import "./battle.css"
import { FaDiceOne, FaDiceTwo, FaDiceThree, FaDiceFour, FaDiceFive, FaDiceSix } from "react-icons/fa";
import { getToken, baseURL } from '../../utils/utils';
const Dice = ({ onClick }) => {
    const [diceData, setDiceData] = useState({
        dice1: 0,
        dice2: 0,
        dice3: 0,
        dice4: 0,
        dice5: 0,
        dice6: 0
    });

    const [selectedDice, setSelectedDice] = useState({
        dice1: false,
        dice2: false,
        dice3: false,
        dice4: false,
        dice5: false,
        dice6: false
    });

    const isAnyDiceSelected = () => {
        return Object.values(selectedDice).some(selected => selected);
    };


    const toggleSelected = (dice,diceValue) => {
        setSelectedDice(prev => {
            const isCurrentlySelected = prev[dice];
            
            // Update diceNum based on whether the dice is being selected or deselected
            var newNum = diceNum;
            if(isCurrentlySelected){
                newNum -= diceValue;
            } else {
                newNum += diceValue;
            }
            if(newNum < 0){
                newNum = 0;
            }
            setDiceNum(newNum);
            
            // Toggle the selected state
            return {
              ...prev,
              [dice]: !isCurrentlySelected
            };
        });
    };
    const diceIcons = [FaDiceOne, FaDiceTwo, FaDiceThree, FaDiceFour, FaDiceFive, FaDiceSix];

    const [diceNum, setDiceNum] = useState(0);
    const handleDiceNumChange = (event) => {
        setDiceNum(parseInt(event.target.value));
    }
    const inputs = [
        { type: 'number', name: 'num', label: 'Number', placeholder: "Enter number of dice you want to roll", required: true }
    ];

    const handleDiceRange = (diceRange) => {
        const valuesList = Object.values(diceData);
        let summ = 0;
        const diceKeys = ['dice1', 'dice2', 'dice3', 'dice4', 'dice5', 'dice6'];

        for (let index = diceRange-1; index < valuesList.length; index++) {
            const element = valuesList[index];
            summ += element;
        }
        let newSelectedDiceState = {};
        for (let index = 0; index < valuesList.length; index++) {
            const diceKey = diceKeys[index];
            if (index < diceRange - 1) {
                newSelectedDiceState[diceKey] = false; 
            } else {
                newSelectedDiceState[diceKey] = true;
            }
        }

        setSelectedDice(prevState => ({
            ...prevState,
            ...newSelectedDiceState,
        }));

        setDiceNum(summ);
    }

    const handleRoll = () => {
        // You can now send formData to your server using fetch or Axios
        fetch(baseURL() + 'battle/dice', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "dice_num": diceNum })
        })
            .then(response => {
                // Check if response is okay
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse response as JSON
            })
            .then(data => {
                // Handle fetched data
                setDiceData({
                    dice1: data.dice_1,
                    dice2: data.dice_2,
                    dice3: data.dice_3,
                    dice4: data.dice_4,
                    dice5: data.dice_5,
                    dice6: data.dice_6
                });
                setSelectedDice({
                    dice1: false,
                    dice2: false,
                    dice3: false,
                    dice4: false,
                    dice5: false,
                    dice6: false
                });
                setDiceNum(0);
            })
    };

    const handleReRoll = () => {
        // You can now send formData to your server using fetch or Axios
        fetch(baseURL() + 'battle/dice', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ "dice_num": diceNum })
        })
            .then(response => {
                // Check if response is okay
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse response as JSON
            })
            .then(data => {
                // Handle fetched data
                // diceData
                // selectedDice
                setDiceData({
                    dice1: selectedDice.dice1 ? data.dice_1 : diceData.dice1 + data.dice_1, 
                    dice2: selectedDice.dice2 ? data.dice_2 : diceData.dice2 + data.dice_2, 
                    dice3: selectedDice.dice3 ? data.dice_3 : diceData.dice3 + data.dice_3, 
                    dice4: selectedDice.dice4 ? data.dice_4 : diceData.dice4 + data.dice_4, 
                    dice5: selectedDice.dice5 ? data.dice_5 : diceData.dice5 + data.dice_5, 
                    dice6: selectedDice.dice6 ? data.dice_6 : diceData.dice6 + data.dice_6
                });
                setSelectedDice({
                    dice1: false,
                    dice2: false,
                    dice3: false,
                    dice4: false,
                    dice5: false,
                    dice6: false
                });
                setDiceNum(0);
            })
    };


    return (
        <div className='card battle_detail_container' >

            <div className='button'>
                <RxCross1 onClick={onClick} />
            </div>

            <div className='dice_bar'>
                <div className='dice_container'>
                    {Object.keys(diceData).map((dice, index) => {
                        const DiceIcon = diceIcons[index]; // Get the correct dice icon
                        return (
                            <div
                                key={dice}
                                className={`dice_card ${selectedDice[dice] ? 'selected' : ''}`}
                                onClick={() => toggleSelected(dice, diceData[dice])}
                            >
                            <DiceIcon />
                            <span>{diceData[dice]}</span>
                        </div>
                        );
                    })}
                </div>

                <div className='number_container'>
                    <div className='dice_number'>{diceNum}</div>
                    <div className='range_container'>
                        <div className='range_card' onClick={()=>{handleDiceRange(1)}}>All</div>
                        <div className='range_card' onClick={()=>{handleDiceRange(2)}}>2+</div>
                        <div className='range_card' onClick={()=>{handleDiceRange(3)}}>3+</div>
                        <div className='range_card' onClick={()=>{handleDiceRange(4)}}>4+</div>
                        <div className='range_card' onClick={()=>{handleDiceRange(5)}}>5+</div>
                        <div className='range_card' onClick={()=>{handleDiceRange(6)}}>6+</div>
                    </div>
                </div>
            </div>

            <form>
                {inputs.map((input) => (
                    <label key={input.name}>
                        <div>{input.label}:</div>
                        <input
                            type={input.type}
                            name={input.name}
                            value={diceNum}
                            onChange={handleDiceNumChange}
                            placeholder={input.placeholder}
                            required={input.required}
                        />
                    </label>
                ))}
            </form>

            <div className='dice_roll_bar'>
                {isAnyDiceSelected() && <button type="submit" onClick={handleReRoll}>Re-Roll</button>}
                <button type='submit' onClick={handleRoll}>Roll</button>
            </div>
        </div>
    )
}

export default Dice