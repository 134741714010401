function baseURL() {
    // return "http://127.0.0.1:5000/"
    return "https://api.librarianapp.com/"
}

function isTokenExists() {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    return !!token; // Returns true if token exists, false otherwise
}

function getToken(){
    // Get token from local storage
    const token = localStorage.getItem("token");
  
    // If token is not found, redirect to home page
    if (token == null) {
      window.location.href = "/";
      return; // Stop further execution
    }
    return token;
}

async function fetchData(endpoint, payload) {
    try {
      payload["token"] = getToken();
      const response = await fetch(baseURL()+endpoint, {
        method: 'POST', // or 'GET' depending on the endpoint
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetch error: ', error);
      return null;
    }
  }


  export const getUsername = async () => {
    const token = localStorage.getItem("token");
  
    // If no token, redirect to the homepage
    if (!token) {
      window.location.href = "/";
      return null;
    }
  
    try {
      const response = await fetch(baseURL() + 'auth/profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: token })
      });
  
      // Check if the response is OK
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      return data.user_name; // Return the username from the response
    } catch (error) {
      console.error('Error fetching username:', error);
      return null; // Return null in case of error
    }
  };

export { isTokenExists, baseURL, getToken, fetchData};