import React, { useState } from 'react';
import './Form.css'
const Form = ({ inputs, onSubmit }) => {
    const [formData, setFormData] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(formData);
        setFormData({}); // Reset form data after submission
    };

    return (
        <form onSubmit={handleSubmit}>
            {inputs.map((input) => (
                <label key={input.name}>
                    <div>{input.label}:</div>
                    
                    <input
                        type={input.type}
                        name={input.name}
                        value={formData[input.name] || ''}
                        onChange={handleChange}
                        placeholder={input.placeholder}
                        required={input.required}
                    />
                </label>
            ))}
            <button type="submit">Submit</button>
        </form>
    );
};

export default Form;