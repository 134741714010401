import React, { useState } from 'react';
import "./market.css"
import { baseURL, getToken } from '../../utils/utils';

const MarketForm = ({back}) => {
  // Default values for the system-generated fields
  let token = getToken();
  // State to store form data
  const [formData, setFormData] = useState({
    description: '',
    img: '',
    token: token,
    title: '',
    units: [{ name: '', price: '', qty: '' }]
  });

  // Handle input changes
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle units changes
  const handleUnitChange = (index, e) => {
    const { name, value } = e.target;
    const updatedUnits = [...formData.units];
    updatedUnits[index][name] = value;
    setFormData({ ...formData, units: updatedUnits });
  };

  // Add a new unit
  const addUnit = () => {
    setFormData({
      ...formData,
      units: [...formData.units, { name: '', price: '', qty: '' }]
    });
  };

  const removeUnit = (index) => {
    const updatedUnits = formData.units.filter((_, i) => i !== index);
    setFormData({ ...formData, units: updatedUnits });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if title, description, and image are filled
    if (!formData.title.trim() || !formData.description.trim() || !formData.img) {
        alert('Please enter values for title, description, and upload an image.');
        return; // Stop form submission if validation fails
    }

    // Check if all units have name, price, and qty filled with valid values
    for (const unit of formData.units) {
        if (!unit.name.trim() || !unit.price || !unit.qty) {
        alert('Please enter valid values for unit name, price, and quantity.');
        return; // Stop form submission if validation fails
        }

        // Further check: Price and Quantity should be greater than 0
        if (unit.price <= 0 || unit.qty <= 0) {
        alert('Price and Quantity should be greater than 0.');
        return; // Stop form submission if validation fails
        }
    }

    // Here, you can send the data to an API, for example
    const response = await fetch(baseURL()+'market/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Sending JSON data
        },
        body: JSON.stringify(formData), // Convert formData object to JSON
    });

    if (response.ok) {
        const result = await response.json();
        alert('Form submitted successfully.');
        window.location.href = "/market";
    }
  };

  const resizeImage = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Define the target size
        let width = img.width;
        let height = img.height;
        const maxSize = 1000; // Set a maximum dimension

        // Resize proportionally
        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
        }

        // Set canvas to the new dimensions
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        // Reduce the image quality if needed to get under 500KB
        canvas.toBlob(
          (blob) => {
            if (blob.size > 500000) {
              // Reduce quality if the blob size is still larger than 500KB
              canvas.toBlob(
                (reducedBlob) => {
                  const reducedReader = new FileReader();
                  reducedReader.readAsDataURL(reducedBlob);
                  reducedReader.onloadend = () => {
                    callback(reducedReader.result);
                  };
                },
                'image/jpeg',
                0.7 // Adjust quality to reduce size
              );
            } else {
              const base64Reader = new FileReader();
              base64Reader.readAsDataURL(blob);
              base64Reader.onloadend = () => {
                callback(base64Reader.result);
              };
            }
          },
          'image/jpeg',
          0.9 // Initial quality setting
        );
      };
    };
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      resizeImage(file, (base64) => {
        if (base64.startsWith('data:image')) {
            base64 = base64.replace(/^data:image\/[a-z]+;base64,/, '');
        }
        setFormData({
          ...formData,
          img: base64, // Save the resized Base64 image
        });
      });
    } else {
      alert('Please upload an image smaller than 5MB.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form">
  <button onClick={() => back()} className="back-button">{'<'}</button>

  <div className="form-group">
    <label htmlFor="title">Title:</label>
    <input
      type="text"
      name="title"
      value={formData.title}
      onChange={handleInputChange}
      id="title"
    />
  </div>

  <div className="form-group">
    <label htmlFor="description">Description:</label>
    <input
      type="text"
      name="description"
      value={formData.description}
      onChange={handleInputChange}
      id="description"
    />
  </div>

  <label className="units-label">Units:</label>
  <div className="unit-container">
    {formData.units.map((unit, index) => (
      <div className="unit-row" key={index}>
        <div className="unit-input-group">
          <label htmlFor={`unit-name-${index}`}>Name:</label>
          <input
            type="text"
            name="name"
            value={unit.name}
            onChange={(e) => handleUnitChange(index, e)}
            id={`unit-name-${index}`}
          />
        </div>

        <div className="unit-input-group">
          <label htmlFor={`unit-qty-${index}`}>QTY:</label>
          <input
            type="number"
            name="qty"
            value={unit.qty}
            onChange={(e) => handleUnitChange(index, e)}
            id={`unit-qty-${index}`}
          />
        </div>

        <div className="unit-input-group">
          <label htmlFor={`unit-price-${index}`}>Price:</label>
          <input
            type="number"
            name="price"
            value={unit.price}
            onChange={(e) => handleUnitChange(index, e)}
            id={`unit-price-${index}`}
          />
        </div>

        <button type="button" className="remove-unit-button" onClick={() => removeUnit(index)}>
          X
        </button>
      </div>
    ))}
  </div>

  <button type="button" className="add-unit-button" onClick={addUnit}>
    Add Unit
  </button>

  <div className="form-group">
    <label htmlFor="upload-image">Upload Image:</label>
    <input
      type="file"
      accept="image/*"
      onChange={handleImageUpload}
      id="upload-image"
    />
  </div>

  <button type="submit" className="submit-button">Submit</button>
</form>
  );
};

export default MarketForm;
