import React, { useState } from 'react';
import Header from '../../components/Header/Header';
import "./Tactical.css"
import ExpectedDamage from './ExpectedDamage';
function TacticalPage() {
    const [selectedFeature, setSelectedFeature] = useState(null);
    const handleCardClick = (feature) => {
        setSelectedFeature(feature);
    };

    const FeatureDisplay = ({feature, setSelectedFeature }) => {
        return (
          <div className='card'>
            <div className='feature_container'>
                <button onClick={() => setSelectedFeature(null)}>Back to Tactical</button>
                {feature === "damage" ? <ExpectedDamage /> : <></>}
            </div>
            
          </div>
        );
    };

    return (
        <div className='tactical'>
            <Header />
            <div className='container battle_container'>
            {!selectedFeature ? (
                <>
                    <div className='card'>
                        <h2>Expected Damage</h2>
                        <p>Calculate the potential damage output for each unit</p>
                        <button onClick={()=>{handleCardClick("damage")}}>Open</button>
                    </div>
                </>
            ):(
                <>
                    <FeatureDisplay feature={selectedFeature} setSelectedFeature={setSelectedFeature} />
                </>
            )}
            </div>

        </div>
    );
    
}

export default TacticalPage;
