import {React} from 'react'
import "./battle.css"
import ProgressBar from './ProgressBar';
import { TbProgress } from "react-icons/tb";
import { FcApproval,FcHighPriority} from "react-icons/fc";

const BattleCard = ({ battle, onClick}) => {
    let owner = battle.owner;
    let player = battle.player;

    let user = battle.user 

    return (
        <div className='card battle_card'>
            <div className='turn_status'>
                Turn: {battle.turn}
            </div>

            <div className='battle_short_detail'>

                <div className='player_card' style={{textAlign: "left"}}>
                    <div className='perspective'>
                        {battle.owner == battle.user ?  "You" : "Opp" }
                    </div>
                    <div className='user'>{battle.owner} </div>
                </div>

                <div className='status_card'>
                    {battle.vp[0]} - {battle.vp[1]}
                </div>

                <div className='player_card' style={{textAlign: "right"}}>
                    <div className='perspective'>
                        {battle.player == battle.user ?  "You" : "Opp" }
                    </div>
                    <div className='user'>{battle.player == null ? "unkown":battle.player}</div>
                </div>
            </div>

            <div className='vp_status'>
                <button  onClick={onClick}>Open</button>
            </div>
        </div>
    )
}

export default BattleCard