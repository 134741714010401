import {React} from 'react'
import "./market.css"


const MarketCard = ({ market, onClick}) => {
    let owner = market.owner;
    const unitStrings = market.units.map(unit => `${unit.name}X${unit.qty}`).join(", ");


    return (
        <div className='card market_card' onClick={onClick}>
            <div className='image'>
                <img src={market.img} alt={market.title} />
            </div>

            <div className='detail'>
                <div className='market_title'>
                {market.title}
                </div>
                <div className='list'>
                {unitStrings}
                </div>
            </div>

            <div className='seller'>
                <p>Seller: {market.owner}</p>
            </div>
        </div>

    )
}

export default MarketCard