import React, { useEffect, useState } from 'react';
import './market.css'; // Import the CSS for styling
import { fetchData, getToken } from '../../utils/utils';



const MarketView = ({ back, formData }) => {
    const [userName, setUserName] = useState(''); // State to store user_name

    useEffect(() => {
        const getUserName = async () => {
        let token = getToken();        

        const data = await fetchData("auth/profile", { token });
        
        if (data && data.user_name) {
            setUserName(data.user_name); // Set user_name in state after fetching
        }
        };
        getUserName();
    }, []); 

    const handleDelete = () => {
        fetchData("market/delete",{"market_id":formData._id});
        back();
        window.location.href = "/market";
    }
  return (
    <div className="market-view">
        
      <button onClick={()=>{back()}} className="back">{'<'}</button>
      <div className="form-group">
        <div className="image">
        <img
            src={formData.img}
            alt="Market"
            className="large-image"
        />
        </div>
      </div>

      <div >
        <label>Seller:</label>
        <p>{formData.owner}</p>
      </div>
      

      <div className="form-group">
        <label>Title:</label>
        <p>{formData.title}</p>
      </div>

      <div className="form-group">
        <label>Description:</label>
        <p>{formData.description}</p>
      </div>

      <div className="form-group">
        <label>Units:</label>
        <div className="units-container">
          {formData.units.map((unit, index) => (
            <div key={index} className="unit-row">
              <p><strong>Name:</strong> {unit.name}</p>
              <p><strong>Price:</strong> ${unit.price}</p>
              <p><strong>Quantity:</strong> {unit.qty}</p>
            </div>
          ))}
        </div>
      </div>
      {userName === formData.owner && <button type="button" className="remove-unit-button" onClick={handleDelete}>
            DELETE
        </button>}
      
    </div>
  );
};

export default MarketView;
