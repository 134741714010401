import {React,useState} from 'react'
import "./Header.css"
import { IoMdMenu } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";

const Header = () => {
  const [menuOpen,setMenuOpen] = useState(false);
  const handleMenu = () => {
    setMenuOpen(!menuOpen);
  }
  const jump = (page) => {
    window.location.href= "/"+page;
  }


  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href="/";
  }

  return (
    <header className='header'>
        {menuOpen &&
          <div className='menuItems'>
            <div className='menuItem' onClick={()=>{jump("profile")}}>Profile</div>
            <div className='menuItem' onClick={()=>{jump("battle")}}>Battle</div>
            <div className='menuItem' onClick={()=>{jump("tactical")}}>Tactical</div>
            <div className='menuItem' onClick={()=>{jump("dice")}}>Dice</div>
            <div className='menuItem' onClick={()=>{jump("market")}}>Market</div>
            <div className='menuItem' onClick={handleLogout}>Logout</div>
            
          </div>
        }
        <div className='menu_button' onClick={handleMenu}>
          {!menuOpen && <IoMdMenu  />}
          {menuOpen && <RxCross1  />}
        </div>
        <div className='title'>
          Librarian
        </div>
        
    </header>
  )
}

export default Header