import {React, useState} from 'react'
import Header from '../../components/Header/Header'
import LoginForm from '../../components/LoginForm/LoginForm'
const Home = () => {
  const [error, setError] = useState(null);
  return (
    <div className='homepage'>
        <Header />
        <LoginForm setError={setError} />
    </div>
  )
}

export default Home