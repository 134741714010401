import React, { useState } from 'react';
import './LoginForm.css';
import { baseURL } from '../../utils/utils';
import { Button, Form, Input, Checkbox } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

const LoginForm = ({ setError }) => {
    const [status, setStatus] = useState('login');

    const onFinish = (values) => {
        const {first_name, last_name, username} = values;
        if (status === 'login') {
            console.log("login start:",values);
            handleLogin(username);
        } else {
            handleRegister(first_name, last_name);
            console.log("register start:",values);
        }
    };

    const handleLogin = (username) => {
        fetch(baseURL() + 'auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username}),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status_code === 400) {
                    throw new Error(data.message);
                }
                localStorage.setItem("token", data.token);
                window.location.href = "/profile";
            })
            .catch(error => {
                alert(error.message);
            });
    };

    const handleRegister = (first_name, last_name) => {
        fetch(baseURL() + 'auth/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({first_name, last_name }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.status_code === 400) {
                    throw new Error(data.message);
                }
                localStorage.setItem("token", data.token);
                window.location.href = "/profile";
            })
            .catch(error => {
                alert(error.message);
            });
    };

    const changeStatus = () => {
        setStatus(status === 'login' ? 'register' : 'login');
    };

    return (
        <div className="login-form-container">
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >   
                {status === 'login' && (
                <Form.Item
                    name="username"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                )}
                
                {status === 'register' && (
                    <>
                        <Form.Item
                            name="first_name"
                            rules={[{ required: true, message: 'Please input your First Name!' }]}
                        >
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            rules={[{ required: true, message: 'Please input your Last Name!' }]}
                        >
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        {status === 'login' ? 'Log in' : 'Register'}
                    </Button>
                    Or <a onClick={changeStatus}>{status === 'login' ? 'register now!' : 'login now!'}</a>
                </Form.Item>
            </Form>
        </div>
    );
};

export default LoginForm;
