import './assets/styles/App.css';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home/Home';
import ProfilePage from './pages/Profile/Profile';
import BattlePage from './pages/Battle/BattlePage';
import TacticalPage from './pages/Tactical/Tactical';
import DicePage from './pages/Battle/DicePage';
import MarketPage from './pages/Market/MarketPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/battle" element={<BattlePage dice={"battle"} />} />
        <Route path="/dice" element={<DicePage />} />
        <Route path="/tactical" element={<TacticalPage />} />
        <Route path="/market" element={<MarketPage />} />
      </Routes>
    </Router>
  );
}

export default App;
